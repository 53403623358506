<template>
  <div class="container">
    <div class="content">
      <div class="bread">
        <h5>当前位置：</h5>
        <span>汇成国际 > 发起合伙定制</span>
      </div>
      <div class="intro-bg">
        <img :src="net + intro.pic_a" alt=""/>
      </div>
      <div class="partner">
        <h4>操盘手合作条件</h4>
        <div class="partner-content">
          <img :src="net + intro.pic_b" alt=""/>
          <img :src="net + intro.pic_c" alt=""/>
          <img :src="net + intro.pic_d" alt=""/>
          <img :src="net + intro.pic_e" alt=""/>
        </div>
      </div>
      <div class="form-section">
        <div class="form-content">
          <h4>发起合伙定制</h4>
          <div class="form-item">
            <h5 style="margin-top: 20px">姓名：</h5>
            <input
              type="text"
              v-model="uname"
              placeholder="请输入姓名"
              autocomplete="off"
            />
          </div>
          <div class="form-item">
            <h5>手机号：</h5>
            <input
              type="tel"
              v-model="phone"
              placeholder="请输入手机号"
              autocomplete="off"
            />
          </div>
          <div class="form-item">
            <h5>发起产品需求：</h5>
            <input
              type="text"
              v-model="need"
              placeholder="请输入产品需求"
              autocomplete="off"
            />
          </div>
          <div class="btn">
            <button type="button" @click="setReq">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { setInitiate, getInitiate } from "@network/home";
export default {
  name: "Initiate",
  data() {
    return {
      net:BASE_URL,
      uname: "",
      phone: "",
      need: "",
      reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      intro:{}
    };
  },

  created() {
    this.getInitiate();
  },

  components: {},

  methods: {
    setReq() {
      if (this.uname == "") {
        this.$message.error({
          message: "请输入姓名",
          offset: 300,
        });
        return false;
      } else if (this.phone == "") {
        this.$message.error({
          message: "请输入手机号",
          offset: 300,
        });
        return false;
      } else if (!this.reg.test(this.phone)) {
        this.$message.error({
          message: "请输入正确的手机号",
          offset: 300,
        });
        return false;
      } else if (this.need == "") {
        this.$message.error({
          message: "请输入产品需求",
          offset: 300,
        });
        return false;
      }
      setInitiate(this.uname, this.phone, this.need).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: res.msg,
            offset: 300,
          });
          return false;
        }
        this.$message.success({
          message: res.msg,
          offset: 300,
        });
        this.uname = "";
        this.phone = "";
        this.need = "";
      });
    },

    getInitiate() {
      getInitiate().then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({ message: "获取失败", offset: 300 });
          return false;
        }
        this.intro=res.data;
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
.container {
  background: #f8f8f8;
  .content {
    padding: 20px 0;
    width: 1200px;
    margin: 0 auto;
    .bread {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      h5 {
        font-size: 18px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #000000;
        line-height: 25px;
      }
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .intro-bg{
      img{
        width: 100%;
      }
    }
    .partner{
      margin: 76px 0 62px;
      h4{
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
      }
      .partner-content{
        display: flex;
        justify-content: space-between;
        img{
          width: 292px;
          box-shadow: 0 1px 12px 1px rgba(0,0,0,.1);
        }
      }
    }
    .form-section {
      padding-top: 60px;
      width: 100%;
      height: 548px;
      background: #ffffff;
      border-radius: 8px;
      .form-content {
        width: 320px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 22px;
        }
        .form-item {
          width: 100%;
          h5 {
            margin-top: 30px;
            margin-bottom: 9px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
          input {
            padding-left: 9px;
            display: inline-block;
            width: 100%;
            height: 40px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #dfdfdf;
          }
        }
        .btn {
          margin-top: 40px;
          button {
            cursor: pointer;
            width: 225px;
            height: 44px;
            background: #1578ff;
            border-radius: 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
    }
  }
}
input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dfdfdf;
  line-height: 22px;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dfdfdf;
  line-height: 22px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dfdfdf;
  line-height: 22px;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dfdfdf;
  line-height: 22px;
}
</style>